import { render, staticRenderFns } from "./PipProdutoList.vue?vue&type=template&id=cabfe7cc&"
import script from "./PipProdutoList.vue?vue&type=script&lang=js&"
export * from "./PipProdutoList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports